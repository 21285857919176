a {
  text-decoration: none;
}

html,
body {
  height: 100%;
  width: 100%;
  background-color: #282c34;
}

body {
  background: url(david-barajas-WvTlS8WCmuE-unsplash_small.jpeg) no-repeat
    center center;
  background-size: cover;
}

.button {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: unset;
  padding: 0 0.5rem;
  font-size: 0.9375rem;
  line-height: 1.5em;
  border-radius: 3px;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.button:disabled {
  cursor: default;
  background-color: #a0a8b0;
}

.button-selection {
  margin-bottom: 5px;
  width: 100%;
  display: flex;
  gap: 10px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
}

.form-background {
  background: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.3)
    ),
    url(david-barajas-WvTlS8WCmuE-unsplash.jpg) no-repeat center center fixed;
  background-size: auto;
}

.hidden {
  display: none;
}

h1 {
  border-bottom: 1px #ddd solid;
  margin-bottom: 0;
}

h2 {
  margin-bottom: 0;
}

h3 {
  margin-bottom: 0;
}

.landing-content {
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.landing-logo {
  width: 15%;
}

.landing-page {
  align-self: center;
  width: 100%;
  height: 100%;
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.landing-search {
  margin-top: 20px;
  width: 70%;
  height: 50px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-width: 220px;
}

.landing-search-input {
  font-size: 1em;
  flex: 90%;
  margin-left: 25px;
  height: 100%;
  border: none;
  background: none;
  min-width: 0px;
}

.landing-search-input:focus,
.landing-search-input:active {
  outline: none;
}

.landing-search-button {
  flex: 10%;
  margin-right: 20px;
  border: none;
  background: none;
}

.landing-search-button-icon {
  cursor: pointer;
}

.landing-title {
  font-size: 48pt;
  font-family: Helvetica;
  color: #ffffff;
  letter-spacing: 0;
}

.linked-button {
  cursor: pointer;
  margin: 0;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0 0.25rem;
  font-size: 0.9375rem;
  line-height: 1.5;
  outline: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.linked-button:disabled {
  background-color: #a0a8b0;
  border-color: #a0a8b0;
}

.linked-buttons {
  overflow: hidden;
  border-radius: 3px;
  display: flex;
  flex-direction: row;
  height: 28px;
}

.login-form {
  display: flex;
  margin: auto;
  flex-direction: column;
  background-color: #fff;
  max-height: 200px;
  border-radius: 5px;
  padding: 20px 30px;
}

.login-form-content {
  display: flex;
  flex-direction: column;

  min-width: 250px;
}

.login-form-button {
  align-self: center;
  margin-top: 20px;
  max-width: 100px;
}

.login-form-note {
  font-size: 0.8em;
  text-align: center;
}

.login-form-error {
  font-size: 0.8em;
  color: #f54e42;
  text-align: center;
}

.logo-title {
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.margin-6225 {
  margin-left: 62.25px;
}

.primary {
  color: #fff;
  background-color: #467fcf;
}

.primary:hover {
  background-color: #6099e9;
}

.secondary {
  color: #fff;
  background-color: #868e96;
}

.secondary:hover {
  background-color: #a0a8b0;
}

.subtitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  font-family: "Mukta", sans-serif;
  font-size: 28px;
  color: #333;
}

@media only screen and (max-width: 600px) {
  .logo-title {
    flex-direction: column;
  }

  .landing-logo {
    width: 40%;
    max-width: 128px;
  }

  .landing-content {
    width: 90%;
  }

  .landing-search {
    height: 10vw;
    width: 80%;
    min-height: 25px;
  }

  .landing-search-button {
    margin-right: 7%;
  }

  .landing-search-input {
    margin-left: 7%;
  }

  .landing-title {
    font-size: 8vw;
  }
}

@media only screen and (max-width: 400px) {
}
